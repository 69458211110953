import React, { Component, Fragment } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { Button } from 'react-bootstrap';
import { hopscotch } from 'react-syntax-highlighter/dist/styles';
import { uuid } from 'uuidv4';
// NOTE CONSTANTS BREAKS JEST TESTS. USE TEST CONSTANTS.
import Constants from '../Constants';
// NOTE USE THESE FOR TESTS:
// import Constants from '../TestConstants'

const broodUUID = uuid();
class SnippetHolder extends Component {
	constructor(props) {
		super(props);

		this.createSnippetPrefix = this.createSnippetPrefix.bind(this);
		this.createMonarchSnippets = this.createMonarchSnippets.bind(this);
		this.createBroodInitSnippet = this.createBroodInitSnippet.bind(this);

	}

	createSnippetPrefix() {
		let broodParams = this.props.activeFeeds[0];
		let height;
		let width;

		broodParams.values.forEach((value) => {
			if (value.name === 'height') {
				height = value.value;
			}

			if (value.name === 'width') {
				width = value.value;
			}
		});

		let displayHeader = `<!-- START BROOD -->
<div id='brood_${broodUUID}' class='brood-container' style='height:${height}px !important; width:${width}px !important'>
</div>
<script type="text/javascript">
(function (uuid) {
\tlet allParams = {
\t\tbrood: {`;

		let templateConfig = [];

		broodParams.values.forEach((value) => {
			if (value.name === 'platform' || value.name === 'template' || value.name === 'template_orientation' || value.name === 'template_resolution') {
				templateConfig.push(value);
			} else if (value.name === 'width' || value.name === 'height') {
				return;
			} else {
				displayHeader += `\n\t\t\t'${value.name}': '${value.value}', /* ${value.help} */`;
			}
		});

		displayHeader += `\n\t\t\t'template_config': {`;

		templateConfig.forEach((value, i) => {
			if (i < templateConfig.length - 1) {
				displayHeader += `\n\t\t\t\t'${value.name}': '${value.value}', /* ${value.help} */`;
			} else {
				displayHeader += `\n\t\t\t\t'${value.name}': '${value.value}' /* ${value.help} */`;
			}
		});

		displayHeader += `
\n\t\t\t}
\t\t},
\t\tprovider: {`;

		return displayHeader;
	}

	createSnippets(feed, snippets, additionalIndent, trailingComma, UUID) {
		let y = feed.values.length - 1;
		let originalBaseIndent = 2;
		let originalValueIndent = originalBaseIndent + 1;
		originalBaseIndent += additionalIndent;
		originalValueIndent += additionalIndent;

		let baseIndent = '';
		let valueIndent = '';
		for (let i = 0; i < originalBaseIndent; i++) {
			baseIndent += `\t`;
		}

		for (let i = 0; i < originalValueIndent; i++) {
			valueIndent += `\t`;
		}

		let comma = '';
		if (trailingComma) {
			 comma = `,`;
		}

		if (UUID) {
			let nextUUID = uuid();
			snippets += `
\t\t\t\t"${nextUUID}": {`;
		}

		feed.values.forEach((value, j) => {
			// stop those trailing commas!
			if (j === y) {
				switch (value.type) {
				case 'number':
					snippets += `
${valueIndent}'${value.name}': ${value.value} /* ${value.help} */
${baseIndent}}${comma}`;
					break;
				case 'string':
					let betterValue = value.value.replace(new RegExp(/,\s/, 'g'), ',');
					snippets += `
${valueIndent}'${value.name}': '${betterValue}' /* ${value.help} */
${baseIndent}}${comma}`;
					break;

				case 'single_select':
				case 'multi_select':
					// Remove quotes from array
					if (value.value.startsWith(`[`)) {
						snippets += `
${valueIndent}'${value.name}': ${value.value}, /* ${value.help} */
${baseIndent}}${comma}`;
						break;
					}
					snippets += `
${valueIndent}'${value.name}': '${value.value}' /* ${value.help} */
${baseIndent}}${comma}`;
					break;
				case 'date':
					snippets += `
${valueIndent}'${value.name}': '${value.value.format('YYYY-MM-DD_HH-mm')}' /* ${value.help} */
${baseIndent}}${comma}`;
					break;
				default:
					let errorMsg = `An unknown type was detected. Supported types are 'number', 'string', 'single_select', 'multi_select', and 'date'. \n Recieved ${value.type}`;
					let errorType = 'TYPE_ERR';
					this.props.triggerError(errorMsg, errorType);
					break;
				}
				return;
			}
			// Using commas.
			switch (value.type) {
			case 'number':
				snippets += `
${valueIndent}'${value.name}': ${value.value}, /* ${value.help} */`;
				break;
			case 'string':
				let betterValue = value.value.replace(new RegExp(/,\s/, 'g'), ',');
				snippets += `
${valueIndent}'${value.name}': '${betterValue}', /* ${value.help} */`;
				break;
			case 'single_select':
			case 'multi_select':
				// Remove quotes from array
				if (value.value.startsWith(`[`)) {
					snippets += `
${valueIndent}'${value.name}': ${value.value}, /* ${value.help} */`;
					break;
				}
				snippets += `
${valueIndent}'${value.name}': '${value.value}', /* ${value.help} */`;
				break;

			case 'date':
				snippets += `
${valueIndent}'${value.name}': '${value.value.format('YYYY-MM-DD_HH-mm')}', /* ${value.help} */`;
				break;

			default:
				let errorMsg = `An unknown type was detected. Supported types are 'number', 'string', 'single_select', 'multi_select', and 'date'. \n Recieved ${value.type}`;
				let errorType = 'TYPE_ERR';
				this.props.triggerError(errorMsg, errorType);
				break;
			}
		});
		return snippets;
	}

	createMonarchParamSnippet(rawData) {
		let dataArray = rawData;
		let snippet = '';
		snippet += `
\t\t\tmonarch: {
\t\t\t\tparams: {`;
		dataArray.forEach((feed) => {
			if (~feed.name.indexOf('monarch_params')) {
				snippet = this.createSnippets(feed, snippet, 2, true, false);
			}
		});
		snippet = this.createMonarchSnippets(rawData, snippet);
		return snippet;
	}

	createMonarchSnippets(rawData, snippets) {

		let dataArray = rawData;
		let x = dataArray.length - 1;

		dataArray.forEach((feed, i) => {
			let monarchUUID = uuid();
			let y = feed.values.length - 1;

			if (!~feed.name.indexOf('params') && feed.name !== 'peacock' && feed.name !== 'pelican') {
				snippets += `
\t\t\t\t"${monarchUUID}": {
\t\t\t\t\t'feed': '${feed.name}',
\t\t\t\t\t'params': {`;
				feed.values.forEach((value, j) => {
					// stop those trailing commas!
					if (j === y) {
						switch (value.type) {
						case 'number':
							snippets += `
\t\t\t\t\t\t'${value.name}': ${value.value} /* ${value.help} */
\t\t\t\t\t}`;
							break;
						case 'string':
							let betterValue = value.value.replace(new RegExp(/,\s/, 'g'), ',');
							snippets += `
\t\t\t\t\t\t'${value.name}': '${betterValue}' /* ${value.help} */
\t\t\t\t\t}`;
							break;

						case 'single_select':
						case 'multi_select':
							snippets += `
\t\t\t\t\t\t'${value.name}': '${value.value}' /* ${value.help} */
\t\t\t\t\t}`;
							break;
						case 'date':
							snippets += `
\t\t\t\t\t\t'${value.name}': '${value.value.format('YYYY-MM-DD_HH-mm')}' /* ${value.help} */
\t\t\t\t\t}`;
							break;
						default:
							let errorMsg = `An unknown type was detected. Supported types are 'number', 'string', 'single_select', 'multi_select', and 'date'. \n Recieved ${value.type}`;
							let errorType = 'TYPE_ERR';
							this.props.triggerError(errorMsg, errorType);
							break;
						}
						return;
					}
					// Using commas.
					switch (value.type) {
					case 'number':
						snippets += `
\t\t\t\t\t\t'${value.name}': ${value.value}, /* ${value.help} */`;
						break;
					case 'string':
						let betterValue = value.value.replace(new RegExp(/,\s/, 'g'), ',');
						snippets += `
\t\t\t\t\t\t'${value.name}': '${betterValue}', /* ${value.help} */`;
						break;
					case 'single_select':
					case 'multi_select':
						snippets += `
\t\t\t\t\t\t'${value.name}': '${value.value}', /* ${value.help} */`;
						break;

					case 'date':
						snippets += `
\t\t\t\t\t\t'${value.name}': '${value.value.format('YYYY-MM-DD_HH-mm')}', /* ${value.help} */`;
						break;

					default:
						let errorMsg = `An unknown type was detected. Supported types are 'number', 'string', 'single_select', 'multi_select', and 'date'. \n Recieved ${value.type}`;
						let errorType = 'TYPE_ERR';
						this.props.triggerError(errorMsg, errorType);
						break;
					}
				});
				if (i === x) {
					snippets += `
\t\t\t\t}`;
					return;
				}
				snippets += `
\t\t\t\t},`;
			}
		});
		return snippets;
	}

	createPeacockSnippets(rawData) {
		let dataArray = rawData;
		let snippet = '';

		// Group params data unused
		snippet += `
\t\t\tpeacock: {
\t\t\t\tparams: {},`;

		dataArray.forEach((feed, i) => {
			let trailingComma = false;
			if (i < dataArray.length - 1) {
				trailingComma = true;
			}
			snippet = this.createSnippets(feed, snippet, 2, trailingComma, true);
		});
		return snippet;
	}

	createPelicanSnippets(rawData) {
		let dataArray = rawData;
		let snippet = '';

		// Group params data unused
		snippet += `
\t\t\tpelican: {
\t\t\t\tparams: {},`;

		dataArray.forEach((feed, i) => {
			let trailingComma = false;
			if (i < dataArray.length - 1) {
				trailingComma = true;
			}
			snippet = this.createSnippets(feed, snippet, 2, trailingComma, true);
		});
		return snippet;
	}

	createBroodInitSnippet(feed, j) {
		let snippet = `
\t\t}
\t}
\twindow.BROOD_SERVER = '${window.location.host}';
\twindow.BROOD_PROXY_SERVER = 'localhost:3333';
\tfunction initBrood() {`;

		if (this.props.monarch) {
			snippet += `
\t\twindow.MONARCH_SERVER = 'monarch.touchsource.com';
\t\twindow.MONARCH_PROXY_SERVER = 'localhost:3333';`;
			snippet += this.createProviderInit('monarch');
		}

		if (this.props.peacock) {
			snippet += this.createProviderInit('peacock');
		}

		if (this.props.pelican) {
			snippet += this.createProviderInit('pelican');
		}

		snippet += `
\t\tconst Brood = require('/brood');
\t\tvar brood = new Brood(\`#brood_\${uuid}\`, allParams.brood);

\t\twindow.setTimeout(() => {
\t\t\tbrood.init(uuid);
\t\t}, 5000);`;
		return snippet;
	}

	createProviderInit(provider) {
		let snippet = '';
		let upperCaseProvider = provider[0].toUpperCase() + provider.slice(1);

		snippet += `
\t\tconst ${upperCaseProvider} = require('/${provider}');

\t\tlet ${provider}Keys = Object.keys(allParams.provider.${provider});
\t\tlet ${provider}ProviderParams = allParams.provider.${provider}.params;
	
\t\t${provider}Keys.forEach((key) => {
\t\t\tif (key === 'params') {
\t\t\t\treturn;
\t\t\t}
	
\t\t\tlet params = Object.assign({}, ${provider}ProviderParams, allParams.provider.${provider}[key], {
\t\t\t\tuuid: key,
\t\t\t});
	
\t\t\tvar ${provider} = new ${upperCaseProvider}(params);
	
\t\t\tdocument.body.addEventListener('brood::init', (e) => {
\t\t\t\tif (e.detail.uuid === uuid) {
\t\t\t\t\t${provider}.broodInstance(uuid);
\t\t\t\t\t${provider}.init(uuid);
\t\t\t\t}
\t\t\t});
\t\t});\n`;

		return snippet;
	}

	createSnippetSuffix() {
		let snippet = `
\t};
\n\tvar proxyFetched = false;
\tfunction fetchBrood() {
\t\tif (typeof Brood === 'undefined') {
\t\t\tlet p = window.location.protocol;
\t\t\tlet broodScript = \`$\{p\}//$\{window.BROOD_SERVER\}/brood/dist/brood.js\`
\t\t\tif (window.BROOD_PROXY_SERVER) {
\t\t\t\tbroodScript = \`$\{p\}//$\{window.BROOD_PROXY_SERVER\}/?url=$\{encodeURIComponent(p + '//' + window.BROOD_SERVER + '/brood/dist/brood.js')\}&update=1\`
\t\t\t}
\t\t\tconsole.info(broodScript);\n
\t\t\tlet script = document.createElement('script');
\t\t\tscript.type = 'text/javascript';
\t\t\tscript.src = broodScript;
\t\t\tscript.onload = initBrood;
\t\t\tscript.onerror = () => {
\t\t\t\tif (!proxyFetched) {
\t\t\t\t\tconsole.warn("WARN: brood.js must be loaded before use...");
\t\t\t\t\tproxyFetched = true;
\t\t\t\t}
\t\t\t\tsetTimeout(() => {
\t\t\t\t\tconsole.error('Brood failed to fetch: ' + broodScript);
\t\t\t\t\tfetchBrood();
\t\t\t\t}, 5000);
\t\t\t\treturn;
\t\t\t};
\t\t\tdocument.body.appendChild(script);
\t\t} else {
\t\tinitBrood();
\t\t}
\t};
\twindow.addEventListener('load', fetchBrood);
})('${broodUUID}');
</script>
<!-- END BROOD -->`;
		return snippet;
	}

	storeClipboard = (clip) => {
		navigator.clipboard.writeText(clip)
		.then(() => {
			console.log(clip)
		  })
		.catch(() => {
			console.error('Failed to copy!')
		});
	  }

	render() {
		if ((this.props.monarch || this.props.pelican || this.props.peacock)) {
			let prefix = this.createSnippetPrefix();
			// let bundle = this.createBundle(this.props.activeFeeds[0]);
			let broodInitSnippet = this.createBroodInitSnippet();
			let suffix = this.createSnippetSuffix();

			let monarchFeeds = [];
			let peacockFeeds = [];
			let pelicanFeeds = [];

			this.props.activeFeeds.forEach((feed) => {
				if (!~feed.name.indexOf('params') && feed.name !== 'peacock' && feed.name !== 'pelican') {
					monarchFeeds.push(feed);
				}
				if (feed.name === 'monarch_params') {
					monarchFeeds.push(feed);
				}
				if (feed.name === 'peacock') {
					peacockFeeds.push(feed);
				}
				if (feed.name === 'pelican') {
					pelicanFeeds.push(feed);
				}
			});

			let snippets = '';
			if (this.props.monarch) {
				snippets += this.createMonarchParamSnippet(monarchFeeds);

				if (this.props.peacock || this.props.pelican) {
					snippets += `\n\t\t\t},`;
				} else {
					snippets += `\n\t\t\t}`;
				}
				// snippets += this.createMonarchSnippets(this.props.activeFeeds);
			}

			if (this.props.peacock) {
				snippets += this.createPeacockSnippets(peacockFeeds);

				if (this.props.pelican) {
					snippets += `\n\t\t\t},`;
				} else {
					snippets += `\n\t\t\t}`;
				}
			}

			if (this.props.pelican) {
				snippets += this.createPelicanSnippets(pelicanFeeds);
				snippets += `\n\t\t\t}`;
			}

			let finalDisplay = prefix + snippets + broodInitSnippet + suffix;
			return (
				<Fragment>
					<Button className='snippet-clipboard' bsStyle='success' onClick={() =>{this.storeClipboard(finalDisplay)}}>
						Copy to Clipboard
					</Button>
					<SyntaxHighlighter language='javascript' className='snippet' style={hopscotch}>{finalDisplay}</SyntaxHighlighter>
				</Fragment>
			);
		}
		return (

			<SyntaxHighlighter language='javascript' className='snippet' style={hopscotch}>Please choose one or more providers...</SyntaxHighlighter>

		);
	}
}

export default SnippetHolder;

