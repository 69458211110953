import React from 'react';
import { Button, ToggleButtonGroup } from 'react-bootstrap';

const FeedCheckbox = (props) => {
    let text = "Remove Feed";
    let defaultValue = 1;
    let style = "danger";
    if (!props.isChecked) {
      text = "Add Feed"
      defaultValue = false;
      style = "success"
    }

    return (
       <ToggleButtonGroup name="checkbuttons" defaultValue={defaultValue}>
          <Button bsStyle={style} onClick={(e) => {props.triggerInUseChange(e, props.id)}} value={1}>
            {text}
          </Button>
       </ToggleButtonGroup>
     );
}
export default FeedCheckbox
