import React, { Component } from 'react';
import { Col, FormControl } from 'react-bootstrap';

class FeedSelect extends Component {

  render () {
    const options = this.props.options.map((option, i) => {
      if (option.val === this.props.default) {
        return <option key={i} value={option.val} selected={true}>{option.key}</option>;
      }
      return <option key={i} value={option.val}>{option.key}</option>;
    });

    return (
      <Col sm={10}>
        <FormControl
          componentClass='select'
          id={this.props.id}
          className={this.props.group}
          data-feed={this.props.feedName}
          onChange={this.props.triggerValueChange}
          >
          {options}
        </FormControl>
      </Col>
    );
  }
}

export default FeedSelect;
