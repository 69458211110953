import React, { Component} from 'react';
import { Col, FormControl } from 'react-bootstrap';

class FeedInput extends Component {
	constructor(props) {
		super(props);

		this.determineFormInput = this.determineFormInput.bind(this);
	}

	determineFormInput() {
		let type;
		switch (this.props.formtype) {
			case 'string':
				type = 'text';
				return type;

			case 'number':
				type = 'number';
				return type

			default:
				if (!this.props.formtype) {
					return;
				}
				let errorMsg = `An unknown type was detected. Supported types are 'number', 'string', 'single_select', 'multi_select', and 'date'. \n Recieved ${this.props.formtype}`;
				let errorType = 'TYPE_ERR';
				this.props.triggerError(errorMsg, errorType);
				break;
		}
	}

	render() {
		let inputType = this.determineFormInput();
		return (
			<Col sm={10}>
				<FormControl
					id={this.props.id}
					type={inputType}
					className={this.props.group}
					defaultValue={this.props.default}
					placeholder={this.props.default}
					data-feed={this.props.feedName}
					onChange={this.props.triggerValueChange}
					/>
			</Col>
		);
	}
}

export default FeedInput;
