import React, { Component } from 'react';
import BundleUrlHolder from './BundleUrlHolder';
import SnippetHolder from './SnippetHolder';
import InUseDisplay from './InUseDisplay';

class SnippetContainer extends Component {
  constructor(props) {
    super(props);

    this.generateInUse = this.generateInUse.bind(this);
    this.generateInUseUrl = this.generateInUseUrl.bind(this);
  }

  generateInUse() {
    // create a stripped down version of the state object for these display elements.
    if (this.props.feeds) {
      let propFeeds = this.props.feeds;
      let inUse = [];
      propFeeds.forEach((feed) => {
        if (!feed) {
          return;
        }
        if (feed.isChecked) {
          let simpleFeed = {};
          simpleFeed.name = feed.name;
          simpleFeed.values = [];
          feed.server.params.forEach((param) => {
            if (param.value) {
              let simpleValue = {};
              simpleValue.type = param.type;
              simpleValue.name = param.name;
              simpleValue.value = param.value;
              simpleValue.help = param.help;
              simpleFeed.values.push(simpleValue);
            }
            return;
          });
          inUse.push(simpleFeed);
        }
        return;
      });
      return inUse;
    }
  }

  generateInUseUrl() {
    if (this.props.feeds) {
      let propFeeds = this.props.feeds;
      let inUse = {};
      propFeeds.forEach((feed) => {
        if (!feed) {
          return;
        }
        if (feed.isChecked) {
          let feedName = feed.name.replace(/\s/g, '_');
          inUse[feedName] = {};
          feed.server.params.forEach((param) => {
            if (param.value) {
              if (param.type === 'date') {
                inUse[feedName][param.name] = param.value.format('YYYY-MM-DD_HH-mm');
                return;
              }

              if (feed.name === "pop_quiz_images" && param.name === "limit") {
                inUse[feedName][param.name] = param.value * 2;
                return;
              }
              inUse[feedName][param.name] = param.value;
            }
          });
        }
      });
      return inUse;
    }
  };

  render() {
    const activeFeeds = this.generateInUse();
    const activeFeedsUrl = this.generateInUseUrl();

    return (
      <div>
        <InUseDisplay
          activeFeeds={activeFeeds}
          pelican={this.props.pelican}
        />
        <SnippetHolder
          activeFeeds={activeFeeds}
          triggerError={this.props.triggerError}
          monarch={this.props.monarch}
          peacock={this.props.peacock}
          pelican={this.props.pelican}
        />
        {/* <BundleUrlHolder
          activeFeedsUrl={activeFeedsUrl}
        /> */}
      </div>
    );
  }
}

export default SnippetContainer;
