import React, {Component} from 'react';
import {Label} from 'react-bootstrap';

class InUseDisplay extends Component {
	constructor(props) {
		super(props);

		this.generateInUseList = this.generateInUseList.bind(this);
	}

	generateInUseList() {
		let count = 0;
		let nameArray = this.props.activeFeeds.map((feed) => {
			if (!~feed.name.indexOf('params')) {
				count++;
				if ((count % 2) > 0) {
					return (<Label className="label-spaced" key={count} bsStyle="primary">{feed.name}</Label>);
				}
				return (<Label className="label-spaced" key={count} bsStyle="info">{feed.name}</Label>);

			}
			return;
		});
		if (count === 0) {
			nameArray = (<Label className="label-spaced" bsStyle="warning">No Feeds Selected</Label>);
		}
		return nameArray;
	}

	render() {

		return (
			<div><h3>Selected Feeds: {this.generateInUseList()}</h3></div>
		);
	}
}

export default InUseDisplay;
