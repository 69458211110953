import React, { Component } from 'react';
// NOTE CONSTANTS BREAKS JEST TESTS. USE TEST CONSTANTS.
import Constants from '../Constants';
// NOTE USE THESE FOR TESTS:
// import Constants from '../TestConstants'

class BundleUrlHolder extends Component {

	constructor(props) {
		super(props);

		this.generateFormNames = this.generateFormNames.bind(this);
		this.generateUrlValues = this.generateUrlValues.bind(this);
		this.generateUrlObj = this.generateUrlObj.bind(this);
		this.assembleUrl = this.assembleUrl.bind(this);
		this.finalizeUrl = this.finalizeUrl.bind(this);
	}

  // Generate a list of the feeds to use to contruct the url.
	generateFormNames() {
		let activeFeeds = this.props.activeFeedsUrl;
		let feedNames = Object.keys(activeFeeds);
		return feedNames;
	}

	// Generate a list of values to use to construct the url.
	generateUrlValues(feedNames) {
		let activeFeeds = this.props.activeFeedsUrl;
		let valueArray = [];

		feedNames.forEach((feed) => {
			let valueNames = Object.keys(activeFeeds[feed]);

			valueNames.forEach((name) => {
				if (valueArray.indexOf(name) === -1) {
					valueArray.push(name);
				}
			})

		});
		return valueArray;
	}

	// Generate an object with strings to assemble into the final url string.
	generateUrlObj(valueArray) {
		let urlObj = {};
		valueArray.forEach((value, i) => {
			if (i === 0) {
				urlObj[value] = `${value}=`;
				return;
			}
			urlObj[value] = `&${value}=`;
			return;
		});
		return urlObj;
	}

	// Assembles the url for display in the render function.
	assembleUrl(feedNames, valueArray, urlObj) {
		let activeFeeds = this.props.activeFeedsUrl;
		valueArray.forEach((value) => {
			feedNames.forEach((feed) => {
					// check if value exists in the feed, if not, append a colon.
					if (activeFeeds[feed].hasOwnProperty(value)) {
						urlObj[value] += `${activeFeeds[feed][value]}${Constants.PS}`;
						return;
					}
					urlObj[value] += `${Constants.PS}`;
					return;
			})
		})
		return urlObj;
	}
	// Trims trailing semicolons and creates a string for the URL tail.
	finalizeUrl(feedNames, urlObj) {
		let urlTail = '';
		let urlPrefix = [];
		let urlKeys = Object.keys(urlObj);

		urlKeys.forEach((key) => {
			if (urlObj[key].substr(-1) === `${Constants.PS}`) {
				urlTail += urlObj[key].slice(0, -1);
				return;
			}
			urlTail += urlObj[key];
		});

		feedNames.forEach((feed) => {
			if (feed !== 'bundle_parameters') {
				urlPrefix.push(feed);
			}
		});

		let finalPrefix = urlPrefix.join(`${Constants.PS}`);
		let finalUrl = finalPrefix + '?' + urlTail;

		return finalUrl;
	}

	render() {
		let defaultUrl = `//${Constants.SERVER}/monarch/bundle/`
		// let defaultUrl = '//api-test.touchsource.com/monarch/bundle/';
		if (this.props.activeFeedsUrl) {
			let formNames = this.generateFormNames();
			if (formNames.length > 1) {
				let valueNames = this.generateUrlValues(formNames);
				let urlObj = this.generateUrlObj(valueNames);
				let prepUrl = this.assembleUrl(formNames, valueNames, urlObj);
				let finalUrl = this.finalizeUrl(formNames, prepUrl);
				return (
					<div>
						<a id="main_url_holder" href={`${defaultUrl}${finalUrl}`}>{defaultUrl}{finalUrl}</a>
					</div>
				);
			}
		}
		// TODO CHANGE THIS TO SERVER DEFAULTS!
		defaultUrl += `?`;
		return (
			<div className="urlHolder">
				<a id="main_url_holder" href={defaultUrl}>{defaultUrl}</a>
			</div>
		);
	}
}

export default BundleUrlHolder;
