import React, { Component} from 'react';
import { Col, FormControl } from 'react-bootstrap'

class FeedMultiSelect extends Component {

	render() {
		
		const options = this.props.options.map((option, i) => (
      <option key={i} value={option.val} onClick={this.handleMultiSelect}>{option.key}</option>
    ));

    return (
	    <Col sm={10}>
	      <FormControl
	        componentClass='select'
	        id={this.props.id}
					formtype={this.props.formtype}
	        multiple={true}
	        data-feed={this.props.feedName}
					onChange={this.props.triggerValueChange}
					value={this.props.value}
	      >
		      {options}
	      </FormControl>
			</Col>
    );
	}
}

export default FeedMultiSelect;
