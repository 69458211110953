import React, { Component} from 'react';
import { Col, Checkbox } from 'react-bootstrap';
import FeedDateField from './FeedDateField';
import FeedCalendar from './FeedCalendar';
import moment from 'moment';

class FeedDatePicker extends Component {
  constructor (props) {
    super(props);
    this.state = {
      date: moment(),
      isOpen: false,
      isChecked: false
    }
    this.handleDate = this.handleDate.bind(this);
    this.toggleForm = this.toggleForm.bind(this);
    this.handleDateCheck = this.handleDateCheck.bind(this);
  }

  handleDate(date) {
    if (date === null) {
      this.props.triggerDateChange(this.props.id, null);
      return;
    }
    this.setState({ date: date }, () => {
      if (this.state.isChecked === true) {
        // Custom value setting func here.
        this.props.triggerDateChange(this.props.id, date);
      }
    });

  }

  toggleForm() {
    this.setState({isOpen: !this.state.isOpen});
  }

  handleDateCheck(event) {
    if (event.target.checked) {
      this.setState({ isChecked: true }, () => {
        this.handleDate(this.state.date);
      });
    } else {
      this.setState({ isChecked: false }, () => {
        this.handleDate(null);
      });
    }
  }

  render () {
    return (
      <Col sm={10}>
        <Col sm={2}>
          <Checkbox className="check-box" onClick={this.handleDateCheck}>Use Date</Checkbox>
        </Col>
        <Col sm={6}>
          <FeedDateField
            date={this.state.date}
            isOpen={this.state.isOpen}
            toggleForm={this.toggleForm}
          />
        </Col>
        <p>(Click field to toggle calendar)</p>
        <FeedCalendar
          date={this.state.date}
          isOpen={this.state.isOpen}
          handleDate={this.handleDate}
          />
      </Col>
    );
  }
}

export default FeedDatePicker;
