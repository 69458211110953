import React, { Component} from 'react';
import InputMoment from 'input-moment';
import 'input-moment/dist/input-moment.css'
import moment from 'moment';

class FeedCalendar extends Component {
  constructor(props){
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(date) {
    this.props.handleDate(date);
  }

  render () {
    if (this.props.isOpen) {
      return (
        <InputMoment
          moment={this.props.date}
          onChange={this.handleChange}
          prevMonthIcon="glyphicon glyphicon-chevron-left"
          nextMonthIcon="glyphicon glyphicon-chevron-right"
          />
      );
    } else {
      return (
        <div></div>
      );
    }
  }
}

export default FeedCalendar;
