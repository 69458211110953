import React, {Component} from 'react';
import FeedCheckbox from './FeedCheckbox';
import FeedField from './FeedField';
import {Panel, Form} from 'react-bootstrap';
import FeedUrlHolder from './FeedUrlHolder';

class FeedForm extends Component {

	render () {

		const formElms = this.props.formParams.map((param, i) => {

			return (
				<FeedField
					key={i}
					id={param.id}
					name={param.name}
					default={param.default}
					formtype={param.type}
					help={param.help}
					options={param.options}
					value={param.value}
					feedName={this.props.feedName}
					triggerValueChange={this.props.triggerValueChange}
					triggerDateChange={this.props.triggerDateChange}
					triggerError={this.props.triggerError}
				/>
			);

		});

		let style = 'default';

		if (this.props.isChecked && !~this.props.feedName.indexOf('params')) {
			style = 'success';
		}

		const displayName = this.props.feedName.replace(/_/g, ' ');
		// const check = this.checkboxGenerator(displayName, style);

		return (
			<Panel
				className={`relative`}
				bsStyle={style}
				eventKey={this.props.eventNumber}
				defaultExpanded={this.props.defaultExpanded}
			>
				<Panel.Heading>
					<Panel.Title
						onClick={
							(e) => {
								document.getElementById(`toggle_${this.props.id}`).click();
							}
						}
					>
						{displayName}
					</Panel.Title>
					{
						(~this.props.feedName.indexOf('params')) ?
							'' : (
								<FeedCheckbox
									triggerInUseChange={this.props.triggerInUseChange}
									isChecked={this.props.isChecked}
									id={this.props.id}
									className='feedcheckbox'
								/>
							)
					}
					<Panel.Toggle
						id={`toggle_${this.props.id}`}
						style={{
							position: 'absolute',
							top:      '5px',
							left:     '5px'
						}}
						onClick={ () => {
							this.props.toggleExpanded(this.props.id);
						}}
					>
					</Panel.Toggle>
				</Panel.Heading>

				<Panel.Collapse>
					<Panel.Body
					>
						<Form horizontal>
							<FeedUrlHolder
								formData={this.props.formParams}
								name={displayName}
								category={this.props.category}
							/>
							{formElms}
						</Form>
					</Panel.Body>
				</Panel.Collapse>
			</Panel>
		);
	}
}

export default FeedForm;
