import React, { Component } from 'react';
import { ButtonToolbar, Button, Panel } from 'react-bootstrap';

class BundlePreloader extends Component {

  render () {
    let bundleArray = [];

    this.props.feeds.forEach((feed) => {
      if (!feed) {
        return;
      }

      if (feed.bundles) {
        feed.bundles.forEach((bundle) => {
          if(bundleArray.indexOf(bundle) === -1) {
            bundleArray.push(bundle);
          }
        });
      }

    });

    let bundleButtons = bundleArray.map((bundle, i) => {
      if (bundle === this.props.bundleFilter) {
        return (<Button bsStyle="success" key={i} id={`${bundle}_${i}`} onClick={() => {this.props.triggerBundleFilter(bundle)}}>{bundle}</Button>);
      }

      return (<Button bsStyle="info" key={i} id={`${bundle}_${i}`} onClick={() => {this.props.triggerBundleFilter(bundle)}}>{bundle}</Button>);
    });

    return (
      <div>
        <Panel header={<h3>Bundle Quick-Load Buttons</h3>}>
          <ButtonToolbar>
            {bundleButtons}
            <Button bsStyle="danger" onClick={() => {this.props.clearSelections()}}>Reset Forms</Button>
          </ButtonToolbar>
        </Panel>
      </div>
    );
  }
}

export default BundlePreloader;
