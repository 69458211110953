/* eslint-disable no-debugger */
import React, {Component} from 'react';
import {Button, Col} from 'react-bootstrap';
import feather from './feather.png';
import './App.css';
import SnippetContainer from './components/SnippetContainer';
import ErrorDisplay from './components/ErrorDisplay';
import FeedFilter from './components/FeedFilter';
import BundlePreloader from './components/BundlePreloader';
import FeedForm from './components/FeedForm';
import {connectableObservableDescriptor} from 'rxjs/observable/ConnectableObservable';
import kali from '../node_modules/kali/dist/kali';
// NOTE CONSTANTS BREAKS JEST TESTS. USE TEST CONSTANTS.
import Constants from './Constants';
// NOTE USE THESE FOR TESTS:
// import Constants from './TestConstants'

const peacockData = require('./peacock.json');
const pelicanData = require('./pelican.json');
const broodParams = require('./brood_params.json');
const monarchParams = require('./monarch_params.json');

class App extends Component {
	constructor(props) {
		super(props);

		this.state = {
			feeds:          [],
			error:          false,
			errorMsg:       '',
			errorType:      '',
			nameFilter:     '',
			bundleFilter:   false,
			categoryFilter: false,
			brood:          true,
			monarch:        false,
			peacock:        false,
			pelican:        false
		};

		this.getData = this.getData.bind(this);
		this.setData = this.setData.bind(this);
		this.generateForms = this.generateForms.bind(this);
		this.filterForms = this.filterForms.bind(this);
		this.filterByName = this.filterByName.bind(this);
		this.filterByCategory = this.filterByCategory.bind(this);
		this.triggerInUseChange = this.triggerInUseChange.bind(this);
		this.handleInUseChange = this.handleInUseChange.bind(this);
		this.triggerValueChange = this.triggerValueChange.bind(this);
		this.handleValueChange = this.handleValueChange.bind(this);
		this.triggerDateChange = this.triggerDateChange.bind(this);
		this.triggerError = this.triggerError.bind(this);
		this.handleError = this.handleError.bind(this);
		this.triggerNameFilter = this.triggerNameFilter.bind(this);
		this.triggerBundleFilter = this.triggerBundleFilter.bind(this);
		this.triggerCategoryFilter = this.triggerCategoryFilter.bind(this);
		this.handleNameFilter = this.handleNameFilter.bind(this);
		this.handleBundleFilter = this.handleBundleFilter.bind(this);
		this.handleCategoryFilter = this.handleCategoryFilter.bind(this);
		this.clearSelections = this.clearSelections.bind(this);
		this.clearBundleFilter = this.clearBundleFilter.bind(this);
		this.addBundleFilter = this.addBundleFilter.bind(this);
		this.toggleExpanded = this.toggleExpanded.bind(this);
		this.generateMonarch = this.generateMonarch.bind(this);
		this.generatePeacock = this.generatePeacock.bind(this);
		this.generatePelican = this.generatePelican.bind(this);
	}


	componentDidMount() {
		this.getData();
	}

	setData(configObject) {
		console.log('setData');
		// If monarch is not selected, empty the config array.
		if (!this.state.monarch) {
			configObject.splice(0, configObject.length);
		}

		// Add peacock to config object if peacock is selected.
		if (this.state.peacock) {
			configObject.unshift(peacockData);
		}

		// Add pelican to config object if pelican is selected.
		if (this.state.pelican) {
			configObject.unshift(pelicanData);
		}

		// add required values to the application form objects found in config.
		let rawFeeds = configObject.map((obj, i) => {
			if (obj.disabled) {
				return false;
			}

			const idName = obj.name.replace(/\s/g, '_');

			obj['arrayValue'] = i + 1;
			obj['defaultExpanded'] = false;
			obj['id'] = `${idName}_${obj['arrayValue']}`;

			// Automatically select peacock or pelican if present
			if (obj.name === 'peacock' || obj.name === 'pelican') {
				obj['isChecked'] = true;
			} else {
				obj['isChecked'] = false;
			}

			return obj;
		});

		// add the required values to the server.params object for each field for the application forms.
		let nextFeeds = rawFeeds.map((obj, i) => {

			let targetNum = i + 1;
			if (!obj || obj.disabled === true) {
				return false;
			}

			obj.friendlyName = obj.name.replace(/_/g, ' ');

			let nextParams = obj.server.params.map((param, j) => {
				// create names for display and later access.
				let paramName = (param.alias) ? param.alias : param.name;
				if (paramName[0] === '_') {
					paramName = param.name.substr(1, param.name.length);
				}

				param.id = `${paramName}_${targetNum}`;
				param.name = paramName;

				// set default value and create parameter to capture field usage.
				param.value = param.default ? param.default : null;

				param.type = param.type ? param.type : 'string';

				// create default values and display directions for the multi_select type.
				if (param.type === 'multi_select' && param.value) {
					let defaultArray = param.value.split(',');
					// check for spaces.
					let nextArray = defaultArray.map((entry) => {
						let result = entry.replace(/\W+/g, '');
						return result;
					});

					param.value = nextArray;
					// returns here to avoid returning before assignment.
					return param;
				}

				// otherwise, return here.
				return param;

			});

			//set params to the mapped array
			obj.server.params = nextParams;

			return obj;

		});

		// Create bundle params per each provider
		let providerBundles = this.createBundleParams(broodParams, monarchParams);

		// Add each param object to the beginning of nextFeeds array in opposite loop order so broodParams is always first
		for (let i = providerBundles.length - 1; i >= 0; i--) {
			if (this.state[providerBundles[i].category]) {
				nextFeeds.unshift(providerBundles[i]);
			}
		}

		this.setState({feeds: nextFeeds});
	}

	getData() {
		// Check for test-mode:
		if (!this.props.fakefeeds) {

			let url = 'https://monarch.touchsource.com/monarch/config';

			new kali().get(url, {
				success: (_kali, res, data) => {
					console.log(res, data);
					this.setData(data);
				},

				failure: (_kali, err) => {
					console.log(err);
				  let errorType = 'NETWORKING';
					this.setState({error: true, errorMsg: `${err}`, errorType: errorType});
				}
			}, this);

			return;
		}

		let configObject = this.props.fakefeeds;
		// debugger;
		this.setData(configObject);

	}

	createBundleParams(...providers) {
		// debugger;
		let providerEntries = [];

		providers.forEach((provider, i) => {
			let targetFields = [];

			for (let j = 0, x = provider.server.params.length; j < x; j++) {
				targetFields.push(Object.assign({}, provider.server.params[j]));
			}

			// add attributes to the params required by the application to track state.
			let valueFields = targetFields.map((field) => {

				field.value = field.default ? field.default : null;

				let paramName = (field.alias) ? field.alias : field.name;
				if (paramName[0] === '_') {
					paramName = field.name.substr(1, field.name.length);
				}

				field.id = `${paramName}_0`;
				field.name = paramName;

				// special handling for multi-selects.
				if (field.type === 'multi_select' && field.value) {
					let defaultArray = field.value.split(',');
					// check for spaces.
					let nextArray = defaultArray.map((entry) => {
						let result = entry.replace(/\W+/g, '');
						return result;
					});

					field.value = nextArray;
					// returns here to avoid returning before assignment.
					return field;
				}

				return field;
			});

			// initialize required variables for later sorting to ensure consistent feed order in application displays.
			let entry = {
				id:              `${provider.name}_${i}`,
				eventNumber:     i,
				arrayValue:      i,
				name:            `${provider.name}`,
				friendlyName:    `${provider.name}`,
				category:        `${provider.category}`,
				server:          {params: valueFields},
				defaultExpanded: true,
				isChecked:       true
			};

			providerEntries.push(entry);
		});

		return providerEntries;
	}

	triggerInUseChange(e, id) {
		this.handleInUseChange(id);

		// Change peacock state if peacock FeedCheckbox is checked
		if (id.includes('peacock')) {
			this.generatePeacock(e);
		}

		// Change pelican state if pelican FeedCheckbox is checked
		if (id.includes('pelican')) {
			this.generatePelican(e);
		}
	}

	triggerValueChange(evt) {
		let value = evt.target.value;
		let inputId = evt.target.id;
		// create array for multi-select.
		if (evt.target.type === 'select-multiple') {
			value = [];

			for (let i = 0, x = evt.target.options.length; i < x; i++) {
				let option = evt.target.options[i];
				if (option.selected) {
					value.push(option.value);
				}
			}
		}
		this.handleValueChange(inputId, value);
	}

	handleInUseChange(id) {

		const prevFeeds = this.state.feeds;
		const nextFeeds = prevFeeds.map((feed) => {
			if (!feed) {
				return;
			}

			if (feed.id === id) {
				feed.isChecked = !feed.isChecked;
			}

			return feed;
		});

		this.setState({feeds: nextFeeds});
	}

	handleValueChange(inputId, value) {
		// get the leading digits of the id.
		let testInt = inputId.match(/(\d+)/g);
		// intialize old feeds.
		let prevFeeds = this.state.feeds;
		// return new array with only the value changed of the target input.
		let nextFeeds = prevFeeds.map((feed) => {
			if (!feed) {
				return false;
			}
			// find the correct form object, testInt is an array with a single entry.
			if (testInt[0] !== feed.arrayValue.toString()) {
				return feed;
			}

			// find the correct field object and change it's value property.
			feed.server.params.forEach((param) => {
				if (inputId === param.id) {
					param.value = value;
				}
			});
			return feed;
		});

		this.setState({feeds: nextFeeds});
	}

	triggerDateChange(inputId, value) {
		this.handleValueChange(inputId, value);
	}

	triggerError(errorMsg, errorType) {
		this.handleError(errorMsg, errorType);
	}

	handleError(errorMsg, errorType) {
		this.setState({error: true, errorMsg: errorMsg, errorType: errorType});
	}

	toggleExpanded(id) {
		const nextFeeds = this.state.feeds.map((feed) => {
			if (!feed) {
				return;
			}

			if (feed.id === id) {
				feed.defaultExpanded = !feed.defaultExpanded;
				return feed;
			}

			return feed;
		});
	}

	triggerNameFilter(evt) {
		this.handleNameFilter(evt.target.value);
	}

	handleNameFilter(name) {
		this.setState({nameFilter: name});
	}

	triggerBundleFilter(bundle) {
		this.handleBundleFilter(bundle);
	}

	clearBundleFilter(bundle, callback) {
		const nextFeeds = this.state.feeds.map((feed) => {
			if (!feed) {
				return;
			}
			if (feed.bundles) {
				feed.bundles.forEach((feedBundle) => {
					if (bundle === feedBundle) {
						feed.isChecked = false;
					}
				});
			}
			return feed;
		});
		this.setState({feeds: nextFeeds}, callback);
	}

	addBundleFilter(bundle) {
		const nextFeeds = this.state.feeds.map((feed) => {
			if (!feed) {
				return;
			}

			if (feed.bundles) {
				feed.bundles.forEach((feedBundle) => {
					if (bundle === feedBundle) {
						feed.isChecked = true;
					}
				});
			}

			return feed;
		});

		this.setState({feeds: nextFeeds});
	}

	handleBundleFilter(bundle) {
		if (bundle === this.state.bundleFilter) {
			this.setState({bundleFilter: false}, () => {
				this.clearBundleFilter(bundle);
			});
			return;
		}

		if (this.state.bundleFilter) {
			this.clearBundleFilter(this.state.bundleFilter, () => {
				this.addBundleFilter(bundle);
			});
		}

		this.setState({bundleFilter: bundle}, () => {
			return this.addBundleFilter(bundle);
		});

	}

	triggerCategoryFilter(evt) {
		this.handleCategoryFilter(evt.target.value);
	}

	handleCategoryFilter(category) {
		if (category === 'false') {
			this.setState({categoryFilter: false});
			return;
		}

		this.setState({categoryFilter: category});
	}

	clearSelections() {
		const nextFeeds = this.state.feeds.map((feed) => {
			if (!feed) {
				return false;
			}

			if (feed.name !== 'bundle parameters') {
				feed.isChecked = false;
			}

			return feed;
		});
		this.setState({feeds: nextFeeds, bundleFilter: false}, () => {
			this.handleCategoryFilter(false);
			this.handleNameFilter('');

			let filter = document.getElementById('name-filter');
			let select = document.getElementById('cat-select');

			filter.value = '';
			select.value = 'false';
		});
	}

	filterByCategory(feeds) {
		let nextFeeds = [];
		for (let i = 0, x = feeds.length; i < x; i++) {
			if (!feeds[i]) {
				continue;
			}

			if (this.state.categoryFilter === feeds[i].category || feeds[i].name === 'bundle parameters') {
				nextFeeds.push(feeds[i]);
			}
		}

		return nextFeeds;
	}

	filterByName(feeds) {
		let nextFeeds = [];
		for (let i = 0, x = feeds.length; i < x; i++) {
			if (!feeds[i]) {
				continue;
			}

			if (!feeds[i].friendlyName) {
				continue;
			}

			let testName = feeds[i].friendlyName.toUpperCase();
			let testFilter = this.state.nameFilter.toUpperCase();

			if (feeds[i].arrayValue === 0 || testName.indexOf(testFilter) !== -1) {
				nextFeeds.push(feeds[i]);
			}

		}

		return nextFeeds;
	}

	filterForms() {
		let rawFeeds = this.state.feeds;
		let firstFeeds;

		if (this.state.categoryFilter) {
			firstFeeds = this.filterByCategory(rawFeeds);
		}

		let testFeeds = firstFeeds ? firstFeeds : rawFeeds;
		let secondFeeds;

		if (this.state.nameFilter) {
			secondFeeds = this.filterByName(testFeeds);
		}

		if (secondFeeds) {
			return secondFeeds;
		} else if (firstFeeds) {
			return firstFeeds;
		}
		return rawFeeds;

	}

	generateForms(feeds) {
		let finalFeeds = feeds.map((feed, i) => {
			if (!feed) {
				return;
			}

			return (
				<FeedForm
					key={i}
					id={feed.id}
					eventNumber={i}
					feedName={feed.name}
					friendlyName={feed.friendlyName}
					category={feed.category}
					formParams={feed.server.params}
					defaultExpanded={feed.defaultExpanded}
					isChecked={feed.isChecked}
					triggerInUseChange={this.triggerInUseChange}
					triggerValueChange={this.triggerValueChange}
					triggerDateChange={this.triggerDateChange}
					triggerError={this.triggerError}
					toggleExpanded={this.toggleExpanded}
				/>
			);
		});

		return finalFeeds;
	}

	generateMonarch(e) {
		e.preventDefault();
		this.setState((prevState) => {
			return {monarch: !prevState.monarch}
			;
		}, () => {
			this.getData();
		});
	}

	generatePeacock(e) {
		e.preventDefault();
		this.setState((prevState) => {
			return {peacock: !prevState.peacock}
			;
		}, () => {
			this.getData();
		});
	}

	generatePelican(e) {
		e.preventDefault();
		this.setState((prevState) => {
			return {pelican: !prevState.pelican}
			;
		}, () => {
			this.getData();
		});
	}

	render() {
		console.log('STATE:');
		console.log(this.state.feeds);
		let filteredFeeds = this.filterForms();
		let finalFeeds = this.generateForms(filteredFeeds);
		return (
			<div className="App">
				<header className="App-header">
					<h1 className="App-title"><img src={feather} className="App-logo" alt="logo" />Brood Configuration Tool <img src={feather} className="App-logo2" alt="logo" /></h1>
				</header>
				<Col xs={12}>
					<ErrorDisplay
						error={this.state.error}
						errorMsg={this.state.errorMsg}
						errorType={this.state.errorType}
						triggerNameFilter={this.triggerNameFilter}
						triggerBundleFilter={this.triggerBundleFilter}
						triggerCategoryFilter={this.triggerCategoryFilter}
					/>
				</Col>

				{this.state.feeds.length > 0 ?
					<Col xs={12}>
						<Button
							bsStyle={this.state.monarch ? 'success' : 'info'}
							className="provider-btn"
							onClick={this.generateMonarch}>
            Monarch
						</Button>
						<Button
							bsStyle={this.state.peacock ? 'success' : 'info'}
							className="provider-btn"
							onClick={this.generatePeacock}>
            Peacock
						</Button>
						<Button
							bsStyle={this.state.pelican ? 'success' : 'info'}
							className="provider-btn"
							onClick={this.generatePelican}>
            Pelican
						</Button>
					</Col>        :
					''}

				<Col xs={6}>
					{/*<Accordion> */}
					<FeedFilter
						feeds={this.state.feeds}
						categoryFilter={this.state.categoryFilter}
						triggerNameFilter={this.triggerNameFilter}
						triggerBundleFilter={this.triggerBundleFilter}
						triggerCategoryFilter={this.triggerCategoryFilter}
					/>
					<br />
					{finalFeeds}
					{/* </Accordion> */}
				</Col>
				<Col xs={6}>
					<BundlePreloader
						feeds={this.state.feeds}
						bundleFilter={this.state.bundleFilter}
						triggerBundleFilter={this.triggerBundleFilter}
						clearSelections={this.clearSelections}
					/>
					<SnippetContainer
						feeds={this.state.feeds}
						triggerError={this.triggerError}
						monarch={this.state.monarch}
						peacock={this.state.peacock}
						pelican={this.state.pelican}
					/>
				</Col>
			</div>
		);
	}
}

export default App;
