import React, { Component } from 'react';
import { Col, FormControl, Form, Panel, ControlLabel } from 'react-bootstrap';

class FeedFilter extends Component {
  constructor(props) {
    super(props);

    this.generateCategoryList = this.generateCategoryList.bind(this);
  }

  generateCategoryList() {
    let catList = [];
    this.props.feeds.forEach((feed) => {
      if (!feed) {
        return;
      }
      if (feed.category) {
        if (catList.indexOf(feed.category) === -1) {
          catList.push(feed.category);
        }
      }
    });
    return catList;
  }

  render () {
    let rawCategories = this.generateCategoryList();

    let categories = rawCategories.map((category, i) => {
      return <option key={i} value={category}>{category}</option>
    });

    let placeholderText = 'Select a Category';

    if (this.props.categoryFilter) {
      placeholderText = 'Clear Filter';
    }

    return (
      <div>
        <Panel
          header={<h4>Search & Filter by Name & Category</h4>}
          >
        <Form horizontal>
          <Col componentClass={ControlLabel} xs={2}>
            Name Search
          </Col>
          <Col xs={4}>
            <FormControl id="name-filter" type="text" placeholder="Search for a Name" onChange={this.props.triggerNameFilter} />
          </Col>
          <Col componentClass={ControlLabel} xs={2}>
            Category Filter
          </Col>
          <Col xs={4}>
            <FormControl id="cat-select" componentClass="select" onChange={this.props.triggerCategoryFilter}>
              <option value='false'>{placeholderText}</option>
              {categories}
            </FormControl>
          </Col>
        </Form>
        </Panel>
      </div>
    );
  }
}

export default FeedFilter;
