import React, {Component} from 'react';
// NOTE CONSTANTS BREAKS JEST TESTS. USE TEST CONSTANTS.
import Constants from '../Constants';
// NOTE USE THESE FOR TESTS:
// import Constants from '../TestConstants'

class FeedUrlHolder extends Component {
	render() {
		if (this.props.name === 'peacock' || this.props.name === 'pelican') {
			return (<div></div>);
		}
		if (!~this.props.name.indexOf('params')) {
			let snippet = `//${Constants.SERVER}/monarch/${this.props.category}/${this.props.name.replace(/\s/g, '_')}?`;
			//let snippet = `//api-test.touchsource.com/monarch/${this.props.category}/${this.props.name.replace(/\s/g, '_')}?`;
			this.props.formData.forEach((data) => {
				if (data.value) {
					if (data.name === 'start_date' || data.name === 'stop_date') {
						let formatValue = data.value.format('YYYY-MM-DD_HH-mm');
						snippet += `${data.name}=${formatValue}&`;
					} else if (this.props.name === 'pop quiz images' && data.name === 'limit') {
						// NOTE here we are doubling the value for pop quiz images to make sure all q's have a's.
						// This could be expanded to include future feeds.
						snippet += `${data.name}=${data.value * 2}&`;
					} else if (data.type === 'string') {
						let betterValue = data.value.replace(new RegExp(/,\s/, 'g'), ',');
						snippet += `${data.name}=${betterValue}&`;
					} else {
						snippet += `${data.name}=${data.value}&`;
					}
				}
			});
			snippet = snippet.slice(0, snippet.length - 1);
			return (<div className="urlHolder" ><a id={`urlHolder_${this.props.name.replace(/\s/g, '_')}`} href={snippet}>{snippet}</a></div>);
		}

		return (<div></div>);
	}
}

export default FeedUrlHolder;
