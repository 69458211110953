import React, { Component} from 'react';
import { Col, FormGroup, HelpBlock, ControlLabel } from 'react-bootstrap';
import FeedSelect from './FeedSelect';
import FeedMultiSelect from './FeedMultiSelect';
import FeedDatePicker from './FeedDatePicker';
import FeedInput from './FeedInput';

class FeedField extends Component {
  constructor(props) {
    super(props);
    this.generateFieldComponents = this.generateFieldComponents.bind(this);
  }

  generateFieldComponents(props) {
    let components = {
      single_select: <FeedSelect key={props.id} {...props} />,
      multi_select: <FeedMultiSelect key={props.id} multiple={true} {...props} />,
      date: <FeedDatePicker key={props.id} {...props} />,
      string: <FeedInput key={props.id} {...props} />,
      number: <FeedInput key={props.id} {...props} />
    }

    let targetComponent = components[props.formtype];
    return targetComponent;
  }

  render () {

    let helpBlock = this.props.help ? this.props.help : '';
    let inputComponent = this.generateFieldComponents(this.props);
    // Check formtype to determine which component to render,
    // Always wil render a companion label and helpblock.
    if (this.props.name === "size") {

        return (
          <FormGroup controlId={this.props.id}>
            <Col componentClass={ControlLabel} htmlFor={this.props.id} sm={2}>{this.props.name}<br/><span className="sublabel">(aspect ratio)</span></Col>
            {inputComponent}
            <HelpBlock>{helpBlock}</HelpBlock>
          </FormGroup>
        )
    }

    return (
      <FormGroup controlId={this.props.id}>
        <Col componentClass={ControlLabel} htmlFor={this.props.id} sm={2}>{this.props.name} </Col>
        {inputComponent}
        <HelpBlock>{helpBlock}</HelpBlock>
      </FormGroup>
    );

  }
}

export default FeedField;
