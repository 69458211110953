import React, { Component } from 'react';
import { Panel } from 'react-bootstrap';

class ErrorDisplay extends Component {
  render () {
    if (this.props.error) {
      switch (this.props.errorType) {

          case 'NETWORKING':
            return (
              <div>
                <Panel bsStyle="danger" header="An Error occurred while retrieving data from the server">
                  <p>Error Returned by the Server: {this.props.errorMsg}</p>
                  <p>Please retry, if the error continues, contact the development team</p>
                </Panel>
              </div>
            );

          case 'TYPE_ERR':
            return (
              <div>
                <Panel bsStyle="danger" header="An Error occurred while processing data from the server">
                  <p>Error Returned by the Server: {this.props.errorMsg}</p>
                  <p>Please refresh the page, if the error continues, contact the development team</p>
                </Panel>
              </div>
            );

          default:
            return (
              <div>
                <Panel bsStyle="danger" header="An Unknown Error occurred">
                  <p>Please refresh the page, if the error continues, contact the development team</p>
                </Panel>
              </div>
            );
      }
    }
    return (<div></div>);
  }
}

export default ErrorDisplay;
