import React, { Component} from 'react';
import { FormControl } from 'react-bootstrap';

class FeedDateField extends Component {
  constructor(props) {
    super(props)

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.props.toggleForm();
  }

  render () {
      return(
        <FormControl type='text' value={this.props.date.format('YYYY-MM-DD_HH-mm')} onClick={this.handleClick} readOnly />
      );
  }
}

export default FeedDateField;
